"use strict";

import Tooltip from 'bootstrap.native/dist/components/tooltip-native.esm';
import {onFind} from "./elements-packages/init-modules-in-scope";

export function init () {
    onFind('[data-toggle="tooltip"]',function (element) {

        let customClass = element.getAttribute('data-custom-tooltip-class');
        if (customClass) {
            new Tooltip(element, {
                template: '<div class="tooltip ' + customClass + '" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
            });
        } else {
            new Tooltip(element);
        }
    });
}