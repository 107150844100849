"use strict";

import loadScript from '@elements/load-script';
import {onFind} from "@elements/init-modules-in-scope";
import {findIn} from "@elements/dom-utils";

export function init () {
    function initOA( oamaps, $map) {
        let layerConfig = (oa.PREFIX === 'oam') ? null : {
            markersActive : true,
            defaultIW : true
        };

        // initialize GeomLayer
        let layer = new oamaps.GeomLayer( _config.outdoorActive.tourId, layerConfig );

        layer.whenLoaded( function () {
            let mapTypeIds = ['oa_map', 'oa_topo', 'oa_map_winter' ];

            let mapConfig = {
                bounds: layer.getBounds(),
                mapTypeId : mapTypeIds[ 0 ],
                mapTypeControlOptions : { mapTypeIds: mapTypeIds }
            };


            let map = oamaps.map( $map, mapConfig );

            // add GeomLayer to map
            layer.setMap( map );
        });

    }


    onFind('.js-tour-map', function ($container) {
        loadMapsAPI().then(() => {
            if ($container) {
                oax.api.maps(function (maps) {
                    initOA(maps, findIn('.js-tour-map__canvas', $container));
                });
            }
        });
    });
}

let promise;
export default function loadMapsAPI() {
    if (promise) {
        return promise;
    }

    promise = new Promise((resolve, reject) => {
        if (!_config.outdoorActive) {
            console.warn('Could not load outdoor map because _cfg.outdoorActive was not set.');
            _config.outdoorActive.key = 'yourtest-outdoora-ctiveapi'; /*dev test key*/
        }

        window.mapApiLoadedCallback = function () {
            resolve();
        };

        loadScript('https://www.outdooractive.com/alpportal/oax_head.js?proj='+ _config.outdoorActive.project +'&key=' + _config.outdoorActive.key + '&callback=window.mapApiLoadedCallback&use_gmap=0&leaflet_gshim=1&lang=' + _config.lang + '&');
    });

    return promise;

}
